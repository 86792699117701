/* eslint-disable */
import _extends from '@babel/runtime/helpers/esm/extends';
import _objectWithoutPropertiesLoose from '@babel/runtime/helpers/esm/objectWithoutPropertiesLoose';
import _objectSpread2 from '@babel/runtime/helpers/esm/objectSpread';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';
import { mapToCssModules, omit, pick, TransitionPropTypeKeys, TransitionTimeouts, tagPropType } from './utils';

var propTypes = _objectSpread2({}, Transition.propTypes, {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  tag: tagPropType,
  baseClass: PropTypes.string,
  baseClassActive: PropTypes.string,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.func]),
});

var defaultProps = _objectSpread2({}, Transition.defaultProps, {
  tag: 'div',
  baseClass: 'fade',
  baseClassActive: 'show',
  timeout: TransitionTimeouts.Fade,
  appear: true,
  enter: true,
  exit: true,
  in: true,
});

function Fade(props) {
  var Tag = props.tag,
    baseClass = props.baseClass,
    baseClassActive = props.baseClassActive,
    className = props.className,
    cssModule = props.cssModule,
    children = props.children,
    innerRef = props.innerRef,
    otherProps = _objectWithoutPropertiesLoose(props, [
      'tag',
      'baseClass',
      'baseClassActive',
      'className',
      'cssModule',
      'children',
      'innerRef',
    ]);

  var transitionProps = pick(otherProps, TransitionPropTypeKeys);
  var childProps = omit(otherProps, TransitionPropTypeKeys);
  return React.createElement(Transition, transitionProps, function (status) {
    var isActive = status === 'entered';
    var classes = mapToCssModules(classNames(className, baseClass, isActive && baseClassActive), cssModule);
    return React.createElement(
      Tag,
      _extends(
        {
          className: classes,
        },
        childProps,
        {
          ref: innerRef,
        },
      ),
      children,
    );
  });
}

Fade.propTypes = propTypes;
Fade.defaultProps = defaultProps;
export default Fade;
