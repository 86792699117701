import React from 'react';
import { useStoreActions, useStoreState } from '../../store/hooks';
import config from '../../config.js';
//stylesheets
import './PackagingInfoModal.scss';

//images
import PackagingInfoImage from '../../images/packagingInfo-image.png';

//components
import BrandModal from '../BrandModal/BrandModal';

const { API_URL } = config;

const PackagingInfoModal = () => {
  const packagingInfoModalShow = useStoreState((state) => state.packagingInfoModal.show);
  const packagingInfoModalToggle = useStoreActions((actions) => actions.packagingInfoModal.toggle);
  const productData = useStoreState((state) => state.product.productData);
  return (
    <BrandModal isOpen={packagingInfoModalShow} toggle={packagingInfoModalToggle} bodyClass="text-center paddingless">
      <h2 className="headline-2 color-brand-deep text-center mb-4">Nos emballages</h2>
      <img
        src={
          productData?.Batch?.product?.Packaging
            ? `${API_URL}/${productData?.Batch?.product?.Packaging}`
            : PackagingInfoImage
        }
        alt="PackagingInfoImage"
        className="d-block w-100"
      />
    </BrandModal>
  );
};

export default PackagingInfoModal;
