import React from 'react';
import { Container } from 'reactstrap';
import Rating from 'react-rating';
import { useStoreState } from '../../store/hooks';

//stylesheets
import './RatingDisplay.scss';

//images
import StarEmpty from '../../images/star_empty.svg';
import StarFull from '../../images/star_full.svg';

//components

const RatingDisplay = () => {
  const productData = useStoreState((state) => state.product.productData);
  const ratingDate = useStoreState((state) => state.ratingFormModal.ratingDate);
  return (
    <>
      <div className="bg-brand-lighter text-center mt-60 pt-30 pb-40">
        <Container fluid>
          <h2 className="headline-2 text-white pt-1">
            Nos clients donnent leur <br /> avis sur ce produit
          </h2>
          <div className="mt-4 pt-1 mb-4">
            <Rating
              initialRating={productData?.rating}
              emptySymbol={<img src={StarEmpty} className="rating-icon" alt="" />}
              placeholderSymbol={<img src={StarEmpty} className="rating-icon" alt="" />}
              fullSymbol={<img src={StarFull} className="rating-icon" alt="" />}
              fractions={2}
              readonly
            />
          </div>
          <div className="text-white body-text-medium">
            Moyenne des notes données par {productData?.totalRating} clients
          </div>
          {ratingDate && (
            <div className="text-white  body-text-small font-italic text-left font-weight-normal mt-4">
              {' '}
              Vous avez donné votre avis le {ratingDate}
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

export default RatingDisplay;
