import { action } from 'easy-peasy';

export default {
  currentDoc: '',
  show: false,
  toggle: action((state) => {
    state.show = !state.show;
  }),
  setCurrentDoc: action((state, payload) => {
    state.currentDoc = payload;
  }),
};
