import React from 'react';
import { useStoreActions, useStoreState } from '../../store/hooks';
import PropTypes from 'prop-types';

//stylesheets
import './TestCategoryItemDetailsModal.scss';

//images

//components
import BrandModal from '../BrandModal/BrandModal';
import getColorDot from '../../utils/getColorDot';

const TestCategoryItemDetailsModal = ({ testCategoryItemDetails, testCategoryItem }) => {
  const testCategoryItemDetailsModalShow = useStoreState((state) => state.testCategoryItemDetailsModal.show);
  const testCategoryItemDetailsModalToggle = useStoreActions((actions) => actions.testCategoryItemDetailsModal.toggle);
  const testCategoryItemModalToggle = useStoreActions((actions) => actions.testCategoryItemModal.toggle);
  const productData = useStoreState((state) => state.product.productData);
  return (
    <BrandModal
      isOpen={testCategoryItemDetailsModalShow}
      toggle={() => {
        testCategoryItemDetailsModalToggle();
        testCategoryItemModalToggle();
      }}
      backButton={true}
      onButtonClose={testCategoryItemModalToggle}
    >
      <div className="has-dot">
        <img src={getColorDot(testCategoryItemDetails?.testItemDetected)} alt="" className="left-dot-2" />
        <h3 className="headline-3 mb-0">{testCategoryItem?.name}</h3>
        <h2 className="headline-2 mb-0 position-relative">{testCategoryItemDetails?.name}</h2>
        <p className="body-text-large color-gray m-0">
          {testCategoryItemDetails?.testItemDetected}{' '}
          {testCategoryItemDetails?.testItemDetected <= 1 ? 'résidu détecté' : 'résidus détectés'}
          {` `}
          {testCategoryItemDetails?.molecules && testCategoryItemDetails?.molecules?.length > 0 && (
            <>sur {testCategoryItemDetails?.molecules?.length}</>
          )}
        </p>
        {testCategoryItemDetails?.molecules && testCategoryItemDetails?.molecules?.length > 0 && (
          <div className="molecule-items">
            {testCategoryItemDetails?.molecules?.map((item, index) => {
              const testItemDetected = productData?.Batch?.testReports?.reduce((acc, testReport) => {
                const testItemMolecules = testReport?.testItemMolecules?.filter(
                  (testItemMolecule) => testItemMolecule?._id === item?._id,
                );
                return acc + testItemMolecules?.length;
              }, 0);
              return (
                <div key={index} className="molecule-item">
                  <img src={getColorDot(testItemDetected)} alt="" className="test-dot" />
                  {item?.name}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="divider my-4" />
      <p className="text-justify body-text-large">{testCategoryItemDetails?.description}</p>
      {/*<p className="body-text-medium color-gray"></p>*/}
    </BrandModal>
  );
};

TestCategoryItemDetailsModal.propTypes = {
  testCategoryItemDetails: PropTypes.object.isRequired,
  testCategoryItem: PropTypes.object.isRequired,
};

export default TestCategoryItemDetailsModal;
