import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { StoreProvider } from 'easy-peasy';
import store from '../../store/config';

// stylesheets
import './App.scss';

//components
import FullScreen from '../FullScreen/FullScreen';
import Routes from '../Routes/Routes';

const App = () => {
  return (
    <StoreProvider store={store}>
      <BrowserRouter>
        <FullScreen>
          <Routes />
        </FullScreen>
      </BrowserRouter>
    </StoreProvider>
  );
};

export default App;
