import React from 'react';
import { useStoreState } from '../../store/hooks';
import config from '../../config.js';

//stylesheets
import './Certificate.scss';

//images

//components
const { API_URL } = config;

const Certificate = () => {
  const productData = useStoreState((state) => state.product.productData);
  return (
    <div className="text-center cer-container">
      {productData?.Batch?.product?.Documentations?.map((item, key) => (
        <div className="cer-item" key={key}>
          {item?.logo ?  <img src={`${API_URL}/${item?.logo}`} alt="" /> : ''}
          {item?.certificate ?
              <a
              href={`${API_URL}/${item?.certificate}`}
              rel="noopener noreferrer"
              target="_blank"
              className="reset-btn link-text-small"
          >
            Le certificat est ici !
          </a> : ''}
        </div>
      ))}
    </div>
  );
};

export default Certificate;
