import React from 'react';
import NavMenu from '../NavMenu/NavMenu';
import FindMoreModal from '../FindMoreModal/FindMoreModal';
import ScoringMethodModal from '../ScoringMethodModal/ScoringMethodModal';
import Header from '../Header/Header';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

const Layout = ({ children, noNavMenu, findMoreBtn }) => {
  const { lot, productId } = useParams();
  return (
    <>
      <Header back={!noNavMenu} findMore={findMoreBtn} />
      {!noNavMenu && <NavMenu lot={lot} productId={productId} />}
      {children}
      <FindMoreModal />
      <ScoringMethodModal />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  findMoreBtn: PropTypes.bool,
  noNavMenu: PropTypes.bool,
};

export default Layout;
