import appStatus from './appStatus.model';
import findLotModal from './findLotModal.model';
import findMoreModal from './findMoreModal.model';
import searchLot from './searchLot.model';
import fullScreen from './fullScreen.model';
import scoringMethodModal from './scoringMethodModal.model';
import ratingModal from './ratingModal.model';
import packagingInfoModal from './packagingInfoModal.model';
import testCategoryItemDetailsModal from './testCategoryItemDetailsModal.model';
import testCategoryItemModal from './testCategoryItemModal.model';
import product from './product.model';
import formulaDetailsModal from './formulaDetailsModal.model';
import reportModal from './reportModal.model';
import siteInfo from './siteInfo.model';
import ratingFormModal from './ratingFormModal.model';

export default {
  appStatus,
  findLotModal,
  findMoreModal,
  searchLot,
  fullScreen,
  scoringMethodModal,
  ratingModal,
  packagingInfoModal,
  testCategoryItemDetailsModal,
  testCategoryItemModal,
  product,
  formulaDetailsModal,
  reportModal,
  siteInfo,
  ratingFormModal,
};
