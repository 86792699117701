import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { useStoreActions, useStoreState } from '../../store/hooks';
import config from '../../config.js';
import {MixPanelProductLand} from '../../utils/mixpanel';

// stylesheets
import './Land.scss';

// images
import QuestionMark from '../../images/question-mark.svg';
//import ProductImage from '../../images/product_image.png';

//components
import SearchLot from '../../components/SearchLot/SearchLot';
import { useHistory, useParams } from 'react-router-dom';
const { API_URL } = config;

const Land = () => {
  const findMoreModalToggle = useStoreActions((actions) => actions.findMoreModal.toggle);
  const findProductByIndex = useStoreActions((actions) => actions.product.findProductByIndex);
  const productLand = useStoreState((state) => state.product.productLand);
  let { productId } = useParams();
  const history = useHistory();

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
  useEffect(() => {
    (async () => {
      let res = null;
      if (productId) {
        res = await findProductByIndex(productId);
        console.log(res);
        MixPanelProductLand(res.productIndex)
      }
      if (!res) {
        history.push(`/`);
      }
    })
    ();
  }, [productId, findProductByIndex, history]);
  return (
    <>
      <div className="landing-page">
        <div className="top-area">
          <Container>
            <h1 className="headline-1 color-brand-deep text-center mb-4">Vous dit tout !</h1>
            <p className="body-text-large color-brand-lighter text-center">
              YOU s’engage à vous dire toute la vérité sur ses produits et sur ce qu’il y a dans le flacon que vous
              tenez entre vos mains.
            </p>
            <div className="text-center mb-4">
              <button type="button" className="reset-btn link-text-medium " onClick={findMoreModalToggle}>
                <span className="pr-2">En savoir plus</span>
                <img src={QuestionMark} alt="Question Mark" />
              </button>
            </div>
          </Container>
        </div>
        <div className="bottom-area">
          <div className="text-center product-image">
            <img src={`${API_URL}/${productLand?.productImage}`} alt="" />
          </div>
          <SearchLot />
        </div>
      </div>
    </>
  );
};

export default Land;
