const addSpace = (value, add = ' ') => {
  if (value) {
    value = value.replace(/[^0-9]/g, '');
    if (value.length > 99) {
      value = value.substr(0, 7) + add + value.substr(7, 7);
    }
  }
  return value;
};

const cleanLot = (value) => {
  return value.replace(/[^0-9]/g, '');
};

export { addSpace, cleanLot };
