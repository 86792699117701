import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useStoreActions, useStoreState } from '../../store/hooks';
import getBrowserFullscreenElementProp from '../../utils/getBrowserFullscreenElementProp';

// stylesheets
import './FullScreen.scss';

const FullScreen = ({ children }) => {
  //Actions
  const fullScreenSet = useStoreActions((actions) => actions.fullScreen.fullScreenSet);
  const fullScreenEnter = useStoreActions((actions) => actions.fullScreen.fullScreenEnter);
  const fullScreenExit = useStoreActions((actions) => actions.fullScreen.fullScreenExit);
  const fullScreenElementSet = useStoreActions((actions) => actions.fullScreen.fullScreenElementSet);

  //States
  const errorMessage = useStoreState((state) => state.fullScreen.errorMessage);
  const fullScreen = useStoreState((state) => state.fullScreen.fullScreen);
  const fullScreenElement = useStoreState((state) => state.fullScreen.fullScreenElement);

  useLayoutEffect(() => {
    document.onfullscreenchange = () => fullScreenSet(document[getBrowserFullscreenElementProp()] != null);
    return () => (document.onfullscreenchange = undefined);
  });

  return (
    <div
      ref={(elem) => {
        fullScreenElementSet(elem);
      }}
      className={`app-root maximizable-container ${fullScreen ? 'fullscreen' : 'default'}`}
    >
      <div className="maximizable-content">{children}</div>
      <div className="maximizable-actions d-none">
        {errorMessage ? (
          <button
            className=""
            onClick={() => alert('Fullscreen is unsupported by this browser, please try another browser.')}
          >
            {errorMessage}
          </button>
        ) : fullScreen ? (
          <button className="" onClick={fullScreenExit}>
            Exit Fullscreen
          </button>
        ) : (
          <button
            className=""
            onClick={() => {
              fullScreenEnter(fullScreenElement);
            }}
          >
            Fullscreen
          </button>
        )}
      </div>
    </div>
  );
};

FullScreen.propTypes = { children: PropTypes.any.isRequired };

export default FullScreen;
