import { action, thunk } from 'easy-peasy';
import getBrowserFullscreenElementProp from '../utils/getBrowserFullscreenElementProp';

export default {
  fullScreenElement: null,
  fullScreenElementSet: action((state, payload) => {
    if (!state.fullScreenElement) {
      state.fullScreenElement = payload;
    }
  }),
  errorMessage: '',
  errorMessageSet: action((state, payload) => {
    state.errorMessage = payload;
  }),
  fullScreen: document[getBrowserFullscreenElementProp()] != null,
  fullScreenSet: action((state, payload) => {
    state.fullScreen = payload;
  }),
  fullScreenEnter: thunk((actions, payload) => {
    if (payload && payload.requestFullscreen) {
      payload
        .requestFullscreen()
        .then(() => {
          actions.fullScreenSet(document[getBrowserFullscreenElementProp()] != null);
        })
        .catch((e) => {
          actions.fullScreenSet(false);
          actions.errorMessageSet = e.message;
        });
    }
  }),
  fullScreenExit: thunk((actions) => {
    actions.fullScreenSet(false);
    try {
      document.exitFullscreen();
    } catch (e) {
      actions.errorMessageSet = e.message;
    }
  }),
};
