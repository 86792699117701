import React, { useState } from 'react';
import { useStoreActions, useStoreState } from '../../store/hooks';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FormGroup, Label, Input, Alert } from 'reactstrap';

//stylesheets
import './RatingFormModal.scss';

//images

//components
import BrandModal from '../BrandModal/BrandModal';

const RatingFormModal = () => {
  const ratingModalToggle = useStoreActions((actions) => actions.ratingModal.toggle);
  const ratingFormModalShow = useStoreState((state) => state.ratingFormModal.show);
  const ratingFormModalToggle = useStoreActions((actions) => actions.ratingFormModal.toggle);
  const givenRating = useStoreState((state) => state.ratingFormModal.rating);
  const ratingDate = useStoreState((state) => state.ratingFormModal.ratingDate);
  const submitRating = useStoreActions((actions) => actions.ratingFormModal.submitRating);
  const productData = useStoreState((state) => state.product.productData);
  const findProductByLot = useStoreActions((actions) => actions.product.findProductByLot);
  const RatingSubmitSchema = yup.object().shape({
    email: yup.string().required('Please enter email').email('Please enter a valid e-mail'),
    agree: yup.boolean().oneOf([true], 'Accept Terms & Conditions is required'),
    subscribe: yup.boolean(),
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  return (
    <BrandModal
      isOpen={ratingFormModalShow}
      toggle={() => {
        ratingFormModalToggle();
        ratingModalToggle();
      }}
      backButton={true}
      onButtonClose={ratingModalToggle}
    >
      <h3 className="headline-3 mb-1">
        Pour confirmer votre note,
        <br /> enregistrez votre e-mail.
      </h3>
      <Formik
        enableReinitialize
        initialValues={{
          email: '',
          agree: false,
          subscribe: false,
        }}
        validationSchema={RatingSubmitSchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            setError('');
            setSuccess('');
            const result = await submitRating({
              rating: givenRating,
              ...values,
              batch: productData?.Batch?._id,
              product: productData?.Batch?.product?._id,
            });
            if (result?.status === 201) {
              await findProductByLot(productData?.Batch?.lotNumber);
              resetForm();
              return setSuccess(result?.data?.msg);
            } else if (result?.response) {
              return setError(result?.response?.data?.msg);
            } else {
              return setError('Something is wrong');
            }
          } catch (e) {
            return setError('Something is wrong');
          }
        }}
      >
        {(renderProps) => (
          <form className="my-4" onSubmit={renderProps.handleSubmit}>
            <FormGroup>
              <Label for="email" className="body-text-large">
                {' '}
                Adresse e-mail
              </Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={renderProps.values.email}
                onChange={(ev) => {
                  renderProps.handleChange(ev);
                }}
                onBlur={renderProps.handleBlur}
                required
                className="input-field"
              />
            </FormGroup>
            <FormGroup className="mt-4">
              <input
                className="styled-checkbox"
                id="agree"
                type="checkbox"
                onChange={(ev) => {
                  renderProps.handleChange(ev);
                }}
                onBlur={renderProps.handleBlur}
                required
                checked={renderProps.values.agree}
              />
              <label htmlFor="agree" className="body-text-medium">
                J’accepte que You conserve mes données de navigation afin de personnaliser mon expérience au sein du
                site
              </label>
            </FormGroup>
            <FormGroup>
              <input
                className="styled-checkbox"
                id="subscribe"
                type="checkbox"
                onChange={(ev) => {
                  renderProps.handleChange(ev);
                }}
                onBlur={renderProps.handleBlur}
                checked={renderProps.values.subscribe}
              />
              <label htmlFor="subscribe" className="body-text-medium">
                J’autorise You à m'envoyer des informations et offres commerciales
              </label>
            </FormGroup>
            <div className="mt-4">
              {renderProps.errors.email && renderProps.touched.email ? (
                <Alert color="danger">{renderProps.errors.email}</Alert>
              ) : null}
              {renderProps.errors.agree && renderProps.touched.agree ? (
                <Alert color="danger">{renderProps.errors.agree}</Alert>
              ) : null}
              {renderProps.errors.subscribe && renderProps.touched.subscribe ? (
                <Alert color="danger">{renderProps.errors.subscribe}</Alert>
              ) : null}
              {error && <Alert color="danger">{error}</Alert>}
              {success && <Alert color="success">{success}</Alert>}
            </div>
            <div className="text-center mt-4">
              {!ratingDate && (
                <button
                  type="submit"
                  className={`min-width-120 reset-btn  body-text-large ${
                    Object.keys(renderProps.errors).length === 0 && Object.keys(renderProps.touched).length > 0
                      ? ' brand-button '
                      : ' gray-button '
                  }`}
                >
                  Valider
                </button>
              )}
            </div>
          </form>
        )}
      </Formik>
    </BrandModal>
  );
};

export default RatingFormModal;
