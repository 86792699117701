import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { useStoreActions, useStoreState } from '../../store/hooks';

//stylesheets
import './ScoringMethodModal.scss';

//images
import GreenDot from '../../images/green-dot.svg';
import OrangeDot from '../../images/orange-dot.svg';
import RedDot from '../../images/red-dot.svg';

//components
import BrandModal from '../BrandModal/BrandModal';

const ScoringMethodModal = () => {
  const scoringMethodModalShow = useStoreState((state) => state.scoringMethodModal.show);
  const scoringMethodModalToggle = useStoreActions((actions) => actions.scoringMethodModal.toggle);
  const getSiteInfo = useStoreActions((actions) => actions.siteInfo.getSiteInfo);
  const siteInfo = useStoreState((state) => state.siteInfo.siteInfo);

  useEffect(() => {
    getSiteInfo();
  }, [getSiteInfo]);

  return (
    <BrandModal isOpen={scoringMethodModalShow} toggle={scoringMethodModalToggle}>
      <h2 className="headline-2 color-brand-deep text-center mb-4">
      {siteInfo?.scoringMethodPageTitle?.split('\n').join('<br />')}
      </h2>

      <Row className="text-center  mb-3">
        <Col xs={4}>
          <img src={GreenDot} alt="" className="mb-2" />
          <p className="body-text-small">Score au-delà de 70%</p>
        </Col>
        <Col xs={4}>
          <img src={OrangeDot} alt="" className="mb-2" />
          <p className="body-text-small">Score entre 30% et 70%</p>
        </Col>
        <Col xs={4}>
          <img src={RedDot} alt="" className="mb-2" />
          <p className="body-text-small">Score en dessous de 30%</p>
        </Col>
      </Row>

    {siteInfo?.scoringMethodPageText?.split('\n').map((i, c) => {
      return (
        <p key={c} className="body-text-large">
          {i}
        </p>
      );
    })}
  </BrandModal>
  );
};

export default ScoringMethodModal;
