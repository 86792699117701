import React, { useState , useRef} from 'react';
import { Col, Container, Row} from 'reactstrap';
import { useStoreActions, useStoreState } from '../../store/hooks';
import config from '../../config.js';
//import {  Page } from 'react-pdf';
//import { Document } from 'react-pdf/dist/entry.webpack';
import FileViewer from 'react-file-viewer';

//stylesheets
import './ReportModal.scss';

// images

//components
import BrandModal from '../BrandModal/BrandModal';
const { API_URL } = config;

const ReportModal = () => {
  const container = useRef(null);
  const currentDoc = useStoreState((state) => state.reportModal.currentDoc);
  const reportModalShow = useStoreState((state) => state.reportModal.show);
  const reportModalToggle = useStoreActions((actions) => actions.reportModal.toggle);
  const [reportImage, setReportImage] = useState();
  const displayF = reportImage ? reportImage : currentDoc?.[0];
  /*
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  function changePage(p) {
    const l = pageNumber+p;
    if(numPages >= l &&  l  > 0){
      setPageNumber(l)
    }
  }
  */
  return (
    <BrandModal isOpen={reportModalShow} toggle={reportModalToggle} bodyClass="paddingless">

      {displayF ? (
        <div ref={container}>
          {/* <!--img
            src={`${API_URL}/${displayF}`}
            alt="ReportImage"
            className="d-block w-100 "
          /-->*/}
          <FileViewer
            fileType={displayF.split('.').pop().toLowerCase()}
            filePath={`${API_URL}/${displayF}`}
          />
          {/*
          container && (
            <div>
              <Document
                file={`${API_URL}/${displayF}`}
                onLoadSuccess={({numPages })=>{setNumPages(numPages )}}
              >
                <Page pageNumber={pageNumber}  width={container?.current?.offsetWidth} />
              </Document>
             <div className="text-center my-3">
               <ButtonGroup>
                 <Button color="primary" onClick={()=>{changePage(-1)}}>Prev page</Button>
                 <span className="d-inline-block pt-2 px-2">{pageNumber} of {numPages}</span>
                 <Button color="primary" onClick={()=>{changePage(1)}}>Next page</Button>
               </ButtonGroup>
             </div>
            </div>
          )
          */}
        </div>
      ) : (
        <div className="text-center mb-5">No preview</div>
      )}
      {currentDoc?.length > 1 && (
        <Container className="report-list">
          <Row className="justify-content-center">
            {currentDoc?.map((docItem, key) => (
              <Col xs={3} key={key}>
                <img
                  src={`${API_URL}/${docItem}`}
                  alt="ReportImage"
                  className="d-block w-100 "
                  onClick={() => {
                    setReportImage(docItem);
                  }}
                />
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </BrandModal>
  );
};

export default ReportModal;
