import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { useStoreActions, useStoreState } from '../../store/hooks';
import { MixPanelProductTests } from '../../utils/mixpanel';

// stylesheets
import './Tests.scss';

// images
import GreenDot from '../../images/green-dot.svg';

// components
import LoadBatch from '../../components/LoadBatch/LoadBatch';
import ReportModal from '../../components/ReportModal/ReportModal';

const Tests = () => {
  const productData = useStoreState((state) => state.product.productData);
  const testAnalysisReports = useStoreState((state) => state.product.testAnalysisReports);
  const noteIcon = useStoreState((state) => state.product.noteIcon);
  const testReportsSortedByLast = useStoreState((state) => state.product.testReportsSortedByLast);
  const reportModalToggle = useStoreActions((actions) => actions.reportModal.toggle);
  const setCurrentDoc = useStoreActions((actions) => actions.reportModal.setCurrentDoc);
  const lotNumber = productData?.Batch?.lotNumber
  const productIndex = productData?.Batch?.product?.productIndex

  useEffect(() => {
    MixPanelProductTests(lotNumber, productIndex)
  }, [lotNumber, productIndex])

  return (
    <>
      <LoadBatch />
      <Container fluid>
        <h2 className="headline-2 color-brand-deep text-center mb-5 mt-3 gras">
          Tests réalisés sur ce
          <br />
          produit
        </h2>
        <ul className="test-details-list">
          {testReportsSortedByLast?.[0] && (
            <li className="mb-5">
              <span className="bg-brand-deep text-white font-weight-bold body-text-medium  mb-2 d-inline-block date-tag">
                {testReportsSortedByLast?.[0]?.testAnalysisReportDate}
              </span>
              <h3 className="headline-3">Analyses des Substances Controversées</h3>
              <p className="body-text-medium color-gray mb-2">Analyses réalisées post-production</p>
              <p className="body-text-medium mt-4">
                <span>Résultats du test :</span> <img src={noteIcon} alt="" className="top-2 ml-1" />
              </p>
              <button
                onClick={() => {
                  reportModalToggle();
                  setCurrentDoc(testAnalysisReports);
                }}
                className="reset-btn link-text-medium"
              >
                Voir le rapport d’analyse détaillé
              </button>
            </li>
          )}
          {productData?.Batch?.analysisReportDate && (
            <li className="mb-5">
              <span className="bg-brand-deep text-white font-weight-bold body-text-medium  mb-2 d-inline-block date-tag">
                {productData?.Batch?.analysisReportDate}
              </span>
              <h3 className="headline-3">Contrôle qualité produit</h3>
              <p className="body-text-medium color-gray mb-2">
                Contrôle du produit face aux spécifications préalablement fixées
              </p>
              <p className="body-text-medium mt-4">
                <span>Résultats du test :</span> <img src={GreenDot} alt="" className="top-2 ml-1" />
              </p>
              <button
                onClick={() => {
                  reportModalToggle();
                  setCurrentDoc([productData?.Batch?.analysisReport]);
                }}
                className="reset-btn link-text-medium"
              >
                Voir le rapport d’analyse détaillé
              </button>
            </li>
          )}
          {productData?.Batch?.product?.dermatologicalTestDate && (
            <li className="mb-5">
              <span className="bg-brand-deep text-white font-weight-bold body-text-medium  mb-2 d-inline-block date-tag">
                {productData?.Batch?.product?.dermatologicalTestDate}
              </span>
              <h3 className="headline-3">Test dermatologique</h3>
              <p className="body-text-medium color-gray mb-2">
                Test pour vérifier la compatibilité du produit avec la peau
              </p>
              <p className="body-text-medium mt-4">
                <span>Résultats du test :</span> <img src={GreenDot} alt="" className="top-2 ml-1" />
              </p>
              <button
                onClick={() => {
                  reportModalToggle();
                  setCurrentDoc([productData?.Batch?.product?.dermatologicalTestReport]);
                }}
                className="reset-btn link-text-medium"
              >
                Voir le rapport d’analyse détaillé
              </button>
            </li>
          )}
        </ul>
      </Container>
      <ReportModal />
    </>
  );
};

export default Tests;
