import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getHomePageLink, getFormulaPageLink, getFabricationPageLink, getTestsPageLink } from '../../utils/navLinks';

// stylesheets
import './NavMenu.scss';

// images
import HomeIcon from '../../images/home.svg';
import HomeIconActive from '../../images/home-active.svg';
import TestsIcon from '../../images/tests.svg';
import TestsIconActive from '../../images/tests-active.svg';
import FormIcon from '../../images/form.svg';
import FormIconActive from '../../images/form-active.svg';
import FabricationIcon from '../../images/fabrication.svg';
import FabricationIconActive from '../../images/fabrication-active.svg';

const NavMenu = ({ lot, productId }) => {
  return (
    <nav className="nav-menu">
      <ul>
        <li>
          <NavLink to={getHomePageLink(productId, lot)} activeClassName="active-menu">
            <img src={HomeIcon} alt="Accueil" className="regular-icon" />
            <img src={HomeIconActive} alt="Accueil" className="active-icon" />
            <span className="nav-text">Accueil</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={getTestsPageLink(productId, lot)} activeClassName="active-menu">
            <img src={TestsIcon} alt="Tests" className="regular-icon" />
            <img src={TestsIconActive} alt="Tests" className="active-icon" />
            <span className="nav-text">Tests</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={getFormulaPageLink(productId, lot)} activeClassName="active-menu">
            <img src={FormIcon} alt="Formule" className="regular-icon" />
            <img src={FormIconActive} alt="Formule" className="active-icon" />
            <span className="nav-text">Formule</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={getFabricationPageLink(productId, lot)} activeClassName="active-menu">
            <img src={FabricationIcon} alt="Fabrication" className="regular-icon" />
            <img src={FabricationIconActive} alt="Fabrication" className="active-icon" />
            <span className="nav-text">Fabrication</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

NavMenu.propTypes = {
  lot: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
};

export default NavMenu;
