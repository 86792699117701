import React from 'react';
import { Switch } from 'react-router-dom';
import {
  getLandPageLink,
  getHomePageLink,
  getFormulaPageLink,
  getFabricationPageLink,
  getTestsPageLink,
  getComingsoonPageLink,
} from '../../utils/navLinks';

//Pages
import Land from '../../pages/Land/Land';
import Home from '../../pages/Home/Home';
import Tests from '../../pages/Tests/Tests';
import Formula from '../../pages/Formula/Formula';
import Fabrication from '../../pages/Fabrication/Fabrication';
import Comingsoon from "../../pages/Comingsoon/Comingsoon";

//components
import RouteWrapper from '../RouteWrapper/RouteWrapper';

const Routes = () => {
  return (
    <>
      <Switch>
        <RouteWrapper exact path={getLandPageLink(':productId')} component={Land} noNavMenu />
        <RouteWrapper exact path={getHomePageLink(':productId', ':lot')} component={Home} findMoreBtn />
        <RouteWrapper exact path={getTestsPageLink(':productId', ':lot')} component={Tests} />
        <RouteWrapper exact path={getFormulaPageLink(':productId', ':lot')} component={Formula} />
        <RouteWrapper exact path={getFabricationPageLink(':productId', ':lot')} component={Fabrication} />
        <RouteWrapper exact path={getComingsoonPageLink()} component={Comingsoon} noNavMenu/>
      </Switch>
    </>
  );
};

export default Routes;
