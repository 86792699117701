import React, { useState } from 'react';
import { useStoreActions, useStoreState } from '../../store/hooks';
import PropTypes from 'prop-types';

//stylesheets
import './TestCategoryItemModal.scss';

//images
import PlusMark from '../../images/plus-mark.svg';

//components
import BrandModal from '../BrandModal/BrandModal';
import TestCategoryItemDetailsModal from '../TestCategoryItemDetailsModal/TestCategoryItemDetailsModal';
import getColorDot from '../../utils/getColorDot';

const TestCategoryItemModal = ({ testCategoryItem }) => {
  const testCategoryItemModalShow = useStoreState((state) => state.testCategoryItemModal.show);
  const testCategoryItemModalToggle = useStoreActions((actions) => actions.testCategoryItemModal.toggle);
  const testCategoryItemDetailsModalToggle = useStoreActions((actions) => actions.testCategoryItemDetailsModal.toggle);
  const [testCategoryItemDetails, setTestCategoryItemDetails] = useState(undefined);
  const productData = useStoreState((state) => state.product.productData);

  return (
    <>
      <BrandModal isOpen={testCategoryItemModalShow} toggle={testCategoryItemModalToggle}>
        <div className="has-dot">
          <img src={getColorDot(testCategoryItem?.testItemDetectedInCat)} alt="" className="left-dot-1" />
          <h2 className="headline-2 mb-0 position-relative">{testCategoryItem?.name}</h2>
          <p className="body-text-large mb-2">{testCategoryItem?.description}</p>
          <p className="body-text-large color-gray">
            {testCategoryItem?.testItemDetectedInCat}{' '}
            {testCategoryItem?.testItemDetectedInCat <= 1 ? 'résidu détecté' : 'résidus détectés'} sur{' '}
            {testCategoryItem?.testItemCatTotal}
          </p>
        </div>
        <div className="divider my-4" />
        <ul className="test-list mb-4">
          {testCategoryItem?.testItems?.map((item, key) => {
            const testItemDetected = productData?.Batch?.testReports?.reduce((acc, testReport) => {
              const testItems = testReport?.testItems?.filter((testItem) => testItem?._id === item?._id);
              const testItemMolecules = testReport?.testItemMolecules?.filter(
                (testItemMolecule) => testItemMolecule?.TestItem?._id === item?._id,
              );
              return acc + testItemMolecules?.length + testItems?.length;
            }, 0);
            return (
              <li
                key={key}
                onClick={() => {
                  testCategoryItemModalToggle();
                  testCategoryItemDetailsModalToggle();
                  setTestCategoryItemDetails({ ...item, testItemDetected });
                }}
              >
                <img src={getColorDot(testItemDetected)} alt="" className="test-dot" />
                <span className="test-title">{item?.name}</span>
                {item?.molecules && item?.molecules?.length > 0 && (
                  <span className="test-subtitle">{item?.molecules?.map((item) => item?.name)?.join(', ')}</span>
                )}
                <span className="residue body-text-small">
                  {testItemDetected} {testItemDetected <= 1 ? 'résidu détecté' : 'résidus détectés'}{' '}
                  {item?.molecules && item?.molecules?.length > 0 && <> / {item?.molecules?.length}</>}
                </span>
                <img src={PlusMark} alt="" className="test-plus-sign" />
              </li>
            );
          })}
        </ul>
        {/*<p className="body-text-medium color-gray"></p>*/}
      </BrandModal>
      {testCategoryItemDetails && testCategoryItem && (
        <TestCategoryItemDetailsModal
          testCategoryItem={testCategoryItem}
          testCategoryItemDetails={testCategoryItemDetails}
        />
      )}
    </>
  );
};

TestCategoryItemModal.propTypes = {
  testCategoryItem: PropTypes.object.isRequired,
};

export default TestCategoryItemModal;
