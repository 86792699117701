import { action } from 'easy-peasy';

export default {
  error: [],
  success: [],
  loading: false,
  setSuccess: action((state, payload) => {
    state.success = state.success.push(payload);
    state.loading = false;
  }),
  setError: action((state, payload) => {
    state.error = state.error.push(payload);
    state.loading = false;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
    state.success = [];
    state.error = [];
  }),
};
