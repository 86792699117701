import React, { useState } from 'react';
import { Container } from 'reactstrap';
import { useStoreActions, useStoreState } from '../../store/hooks';

//stylesheets
import './TestCategory.scss';

//images
import PlusMark from '../../images/plus-mark.svg';

//components
import TestCategoryItemModal from '../TestCategoryItemModal/TestCategoryItemModal';
import getColorDot from '../../utils/getColorDot';

const TestCategory = () => {
  const productData = useStoreState((state) => state.product.productData);
  const testCategoryItemModalToggle = useStoreActions((actions) => actions.testCategoryItemModal.toggle);
  const [testCategoryItem, setTestCategoryItem] = useState(undefined);
  return (
    <>
      <Container className="mt-4 mb-4" fluid>
        <ul className="test-list">
          {productData?.Tests?.map((item, key) => {
            const testItemDetectedInCat = productData?.Batch?.testReports?.reduce((acc, testReport) => {
              const testItems = testReport?.testItems?.filter((testItem) => testItem?.testCategory?._id === item?._id);
              const testItemMolecules = testReport?.testItemMolecules?.filter(
                (testItemMolecule) => testItemMolecule?.TestItem?.testCategory?._id === item?._id,
              );
              return acc + testItemMolecules?.length + testItems?.length;
            }, 0);
            const testItemCatTotal = item?.testItems?.reduce(
              (acc, testItem) =>
                testItem?.testCategory?._id === item?._id
                  ? acc + (testItem?.molecules?.length > 0 ? testItem?.molecules?.length : 1)
                  : acc,
              0,
            );
            const totalItemsInProduct = productData.Batch?.product?.Molecules.length
            console.log("productData", productData )
            console.log("productData", productData.Batch.product.Molecules )
            return (
              <li
                key={key}
                onClick={() => {
                  testCategoryItemModalToggle();
                  setTestCategoryItem({ ...item, testItemDetectedInCat, testItemCatTotal });
                }}
              >
                <img src={getColorDot(testItemDetectedInCat)} alt="" className="test-dot" />
                <span className="test-title">{item?.name}</span>
                <span className="residue body-text-small">
                  {testItemDetectedInCat} {testItemDetectedInCat <= 1 ? 'résidu détecté' : 'résidus détectés'} /{' '}
                  {testItemCatTotal}
                </span>
                <img src={PlusMark} alt="" className="test-plus-sign" />
              </li>
            );
          })}
        </ul>
      </Container>
      {testCategoryItem && <TestCategoryItemModal testCategoryItem={testCategoryItem} />}
    </>
  );
};

export default TestCategory;
