import React from 'react';
import { useStoreActions, useStoreState } from '../../store/hooks';
import PropTypes from 'prop-types';

//stylesheets
import './FormulaDetailsModal.scss';

//images

//components

import BrandModal from '../BrandModal/BrandModal';

const FormulaDetailsModal = ({ formulaDetails }) => {
  const formulaDetailsModalShow = useStoreState((state) => state.formulaDetailsModal.show);
  const formulaDetailsModalToggle = useStoreActions((actions) => actions.formulaDetailsModal.toggle);

  return (
    <BrandModal isOpen={formulaDetailsModalShow} toggle={formulaDetailsModalToggle}>
      <h2 className="headline-2 mb-1">{formulaDetails?.Nom}</h2>
      <p className="body-text-large mb-0">{formulaDetails?.sousTitre}</p>
      <p className="body-text-large  mb-0">{formulaDetails?.Provenance}</p>
      <div className="divider" />
      <p className="body-text-large">{formulaDetails?.descriptionIngredient}</p>
    </BrandModal>
  );
};

FormulaDetailsModal.propTypes = {
  formulaDetails: PropTypes.object.isRequired,
};

export default FormulaDetailsModal;
