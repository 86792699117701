import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { useStoreActions } from '../../store/hooks';
import PropTypes from 'prop-types';
import { getLandPageLink } from '../../utils/navLinks';

// stylesheets
import './Header.scss';

//images
import Logo from '../../images/logo.png';
import BackIcon from '../../images/back.svg';
import QuestionMark from '../../images/question-mark.svg';

const Header = ({ back, findMore }) => {
  let { productId } = useParams();
  const findMoreModalToggle = useStoreActions((actions) => actions.findMoreModal.toggle);
  return (
    <Container className="header" fluid>
      <Row>
        <Col xs={3}>
          {back && (
            <Link to={getLandPageLink(productId)} className="back">
              <img src={BackIcon} alt="BackIcon" />
            </Link>
          )}
        </Col>
        <Col xs={6} className="text-center">
          <Link to="#" className="logo">
            <img src={Logo} alt="CestClair" />
          </Link>
        </Col>
        <Col xs={3} className="text-right">
          {findMore && (
            <button className="reset-btn mt-2" onClick={findMoreModalToggle}>
              <img src={QuestionMark} alt="QuestionMark" />
            </button>
          )}
        </Col>
      </Row>
    </Container>
  );
};

Header.propTypes = {
  back: PropTypes.bool,
  findMore: PropTypes.bool,
};
Header.defaultProps = {
  back: false,
  findMore: false,
};

export default Header;
