import mixpanel from 'mixpanel-browser'

mixpanel.init("fcea0fa80050346e6a0402f0edfce169", { "api_host": "https://api-eu.mixpanel.com" }, "");


const MixPanelProductLand = (productIndex) => {
    mixpanel.track("Produit", {"Numero_Produit": productIndex});
}

const MixPanelProductHome = (lotNumber, productIndex) => {
    mixpanel.track("Lot", {
        "Numero_Produit": productIndex,
        "Numero_Lot": lotNumber
    });
}

const MixPanelProductFabrication = (lotNumber, productIndex) => {
    mixpanel.track("Vue Fabrication", {
        "Numero_Produit": productIndex,
        "Numero_Lot": lotNumber
    });
}

const MixPanelProductFormula = (lotNumber, productIndex) => {
    mixpanel.track("Vue Ingredient", {
        "Numero_Produit": productIndex,
        "Numero_Lot": lotNumber
    });
}

const MixPanelProductTests = (lotNumber, productIndex) => {
    mixpanel.track("Vue Tests", {
        "Numero_Produit": productIndex,
        "Numero_Lot": lotNumber
    });
}

export {
    MixPanelProductFabrication,
    MixPanelProductLand,
    MixPanelProductFormula,
    MixPanelProductHome,
    MixPanelProductTests
};