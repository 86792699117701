import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import { MixPanelProductFormula } from '../../utils/mixpanel';


// stylesheets
import './Formula.scss';

// images
import PlusMark from '../../images/plus-mark.svg';
import { useStoreActions, useStoreState } from '../../store/hooks';

//components
import FormulaDetailsModal from '../../components/FormulaDetailsModal/FormulaDetailsModal';
import LoadBatch from '../../components/LoadBatch/LoadBatch';

const Formula = () => {
  const productData = useStoreState((state) => state.product.productData);
  const formulaDetailsModalToggle = useStoreActions((actions) => actions.formulaDetailsModal.toggle);
  const [formulaDetails, setFormulaDetails] = useState(undefined);
  const lotNumber = productData?.Batch?.lotNumber
  const productIndex = productData?.Batch?.product?.productIndex

  useEffect(() => {
    MixPanelProductFormula(lotNumber, productIndex)
  }, [lotNumber, productIndex])
  
  return (
    <>
      <LoadBatch />
      <Container fluid>
        <h2 className="headline-2 color-brand-deep text-center mb-5 mt-3 gras">Formule de votre {productData?.Batch?.product?.Categorie}</h2>
        <ul className="formula-list mb-5">
          {productData?.Batch?.product?.Ingredients?.map((item, key) => (
            <li
              key={key}
              onClick={() => {
                formulaDetailsModalToggle();
                setFormulaDetails(item);
              }}
            >
              <p className="body-text-medium font-weight-bold mb-1 text-uppercase">{item?.Nom}</p>
              <p className="body-text-medium mb-0">{item?.sousTitre}</p>
              <p className="body-text-medium mb-0">{item?.Provenance}</p>
              <img src={PlusMark} alt="" className="test-plus-sign" />
            </li>
          ))}
        </ul>
        {formulaDetails && <FormulaDetailsModal formulaDetails={formulaDetails} />}
      </Container>
    </>
  );
};

export default Formula;
