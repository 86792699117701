import { useEffect } from 'react';
import { useStoreActions } from '../../store/hooks';
import { useParams, useHistory } from 'react-router-dom';
import { getLandPageLink, getComingsoonPageLink } from '../../utils/navLinks';

const LoadBatch = () => {
  const updateRatingDate = useStoreActions((actions) => actions.ratingFormModal.updateRatingDate);
  const findProductByLot = useStoreActions((actions) => actions.product.findProductByLot);
  let { lot, productId } = useParams();
  const history = useHistory();
  useEffect(() => {
    (async () => {
      let res = null;
      if (lot && productId) {
        res = await findProductByLot({
          lot: lot?.replace(/[^0-9]/g, ''),
          productId,
        });
        updateRatingDate(res?.Batch?.product?._id);
      }
      if (!res) {
        history.push(getLandPageLink(productId));
      }else if(res?.Batch?.Available !== true){
        history.push(getComingsoonPageLink());
      }
    })();
  }, [lot, productId, findProductByLot, history, updateRatingDate]);
  return null;
};

export default LoadBatch;
