const urlPrefix = '/viewProduct';

const getLandPageLink = (productId) => {
  return `${urlPrefix}/${productId}`;
};

const getHomePageLink = (productId, lot) => {
  return `${urlPrefix}/${productId}/${lot}/home`;
};

const getFormulaPageLink = (productId, lot) => {
  return `${urlPrefix}/${productId}/${lot}/formula`;
};

const getFabricationPageLink = (productId, lot) => {
  return `${urlPrefix}/${productId}/${lot}/fabrication`;
};

const getTestsPageLink = (productId, lot) => {
  return `${urlPrefix}/${productId}/${lot}/tests`;
};
const getComingsoonPageLink = () => {
  return `/comingsoon`;
}

export { getLandPageLink, getHomePageLink, getFormulaPageLink, getFabricationPageLink, getTestsPageLink, getComingsoonPageLink };
