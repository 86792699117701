import React, { useState, Fragment } from 'react';
import { Container } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useStoreActions, useStoreState } from '../../store/hooks';
import { addSpace, cleanLot } from '../../utils/formatLotNumber';
import validateLotLength from '../../utils/validateLotLength';
import { getHomePageLink } from '../../utils/navLinks';

// stylesheets
import './SearchLot.scss';

// images
import ArrowBottom2 from '../../images/arrow-white2.svg';

// components
import FindLotModal from '../FindLotModal/FindLotModal';

const SearchLot = () => {
  let ValiderButton, Input;
  const history = useHistory();
  let { productId } = useParams();

  //Actions
  const findLotModalToggle = useStoreActions((actions) => actions.findLotModal.toggle);
  const autoCompleteGet = useStoreActions((actions) => actions.searchLot.autoCompleteGet);
  const autoCompleteSet = useStoreActions((actions) => actions.searchLot.autoCompleteSet);
  const searchSet = useStoreActions((actions) => actions.searchLot.searchSet);
  const fullScreenEnter = useStoreActions((actions) => actions.fullScreen.fullScreenEnter);

  //Global States
  const autoComplete = useStoreState((state) => state.searchLot.autoComplete);
  const search = useStoreState((state) => state.searchLot.search);
  const fullScreenElement = useStoreState((state) => state.fullScreen.fullScreenElement);

  //Local States
  const [error, setError] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);

  const onSubmit = () => {
    autoCompleteSet(null);
    const cleanInput = cleanLot(search);
    if (validateLotLength(cleanInput)) {
      fullScreenEnter(fullScreenElement);
      history.push(getHomePageLink(productId, cleanInput));
    } else {
      setError(true);
      Input.focus();
    }
  };

  const onInputChange = (event) => {
    const { value } = event.target;
    searchSet(value);
    autoCompleteGet({ productId, search: value });
    setError(false);
  };

  return (
    <>
      <div className="search-block bg-brand-lighter py-4">
        <Container>
          <div className="search-input-group text-center">
            <label className="headline-3 text-white">
              Tapez le numéro de lot ici
              <img src={ArrowBottom2} alt="" />
            </label>
            <div
              className={
                'search-input-group-con ' +
                (autoComplete && autoComplete.length > 0 ? 'has-suggestion ' : ' ') +
                (error ? 'has-error ' : ' ') +
                (search ? 'has-value ' : ' ') +
                (inputFocus ? 'has-input-focus ' : ' ')
              }
            >
              <div className="input-wrapper">
                <input
                  type="text"
                  inputMode="numeric"
                  value={search}
                  onChange={onInputChange}
                  onFocus={() => {
                    setInputFocus(true);
                  }}
                  onBlur={() => {
                    setInputFocus(false);
                  }}
                  ref={(input) => {
                    Input = input;
                  }}
                />
                <div className="search-input-placeholder">
                  {Array(13)
                    .fill()
                    .map((_, index) => (
                      <Fragment key={index}>
                        <span />{' '}
                      </Fragment>
                    ))}
                </div>
              </div>
              {autoComplete && autoComplete.length > 0 && (
                <div className="auto-complete">
                  {autoComplete.map((i, index) => (
                    <div
                      className="auto-complete-i"
                      key={index}
                      onClick={() => {
                        searchSet(i);
                        autoCompleteSet(null);
                        ValiderButton.focus();
                      }}
                    >
                      {addSpace(i, '-')}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="text-center mt-3">
            <button type="button" className="reset-btn link-text-large text-white mr-3" onClick={findLotModalToggle}>
              Où trouver le n° de lot ?
            </button>
            <button
              onClick={onSubmit}
              ref={(button) => {
                ValiderButton = button;
              }}
              // disabled={autoComplete}
              className="min-width-120 reset-btn brand-button-white body-text-large"
            >
              Valider
            </button>
          </div>
        </Container>
      </div>
      <FindLotModal />
    </>
  );
};

export default SearchLot;
