import React, { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../store/hooks';

//stylesheets
import './FindMoreModal.scss';

//images

//components
import BrandModal from '../BrandModal/BrandModal';

const FindMoreModal = () => {
  const findMoreModalShow = useStoreState((state) => state.findMoreModal.show);
  const findMoreModalToggle = useStoreActions((actions) => actions.findMoreModal.toggle);
  const getSiteInfo = useStoreActions((actions) => actions.siteInfo.getSiteInfo);
  const siteInfo = useStoreState((state) => state.siteInfo.siteInfo);

  useEffect(() => {
    getSiteInfo();
  }, [getSiteInfo]);
  return (
    <BrandModal isOpen={findMoreModalShow} toggle={findMoreModalToggle}>
      <h1 className="headline-1 color-brand-deep text-center mb-3">
        {siteInfo?.knowMorePageTitle?.split('\n').join('<br />')}
      </h1>
      {siteInfo?.knowMorePageText?.split('\n').map((i, c) => {
        return (
          <p key={c} className="body-text-large">
            {i}
          </p>
        );
      })}
    </BrandModal>
  );
};

export default FindMoreModal;
