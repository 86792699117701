import { action, thunk } from 'easy-peasy';
import axios from 'axios';
import config from '../config.js';
import GreenDotGroup from '../images/green-dot-group.svg';
import OrangeDotGroup from '../images/orange-dot-group.svg';
import RedDotGroup from '../images/red-dot-group.svg';
import OrangeDot from '../images/orange-dot.svg';
import GreenDot from '../images/green-dot.svg';
import RedDot from '../images/red-dot.svg';
import validateLotLength from '../utils/validateLotLength';
const { API_URL } = config;

export default {
  productData: null,
  testAnalysisReports: null,
  testReportsSortedByLast: null,
  totalTestItem: null,
  testItemDetected: null,
  testScore: null,
  noteGroupIcon: null,
  noteIcon: null,
  productLand: null,
  setProductData: action((state, payload) => {
    if (payload) {
      state.productData = payload;
      console.log("payload", payload)
      state.testAnalysisReports = payload?.Batch?.testReports?.map((item) => item?.testAnalysisReport);
      state.testReportsSortedByLast = payload?.Batch?.testReports?.sort(
        (a, b) => new Date(b?.testAnalysisReportDate) - new Date(a?.testAnalysisReportDate),
      );
      state.totalTestItem = payload?.Tests?.reduce((acc, current) => {
        return (
          acc +
          current?.testItems?.reduce((acc2, current2) => {
            return acc2 + (current2?.molecules?.length > 0 ? current2?.molecules?.length : 1);
          }, 0)
        );
      }, 0);

      let totalTestItem2 = payload?.Batch?.product?.Molecules?.length;
      if(totalTestItem2 === 0){
        totalTestItem2 = state.totalTestItem
      }
      
      console.log("totalTestItem2", totalTestItem2)

      state.testItemDetected = payload?.Batch?.testReports?.reduce(
        (acc, testReport) => acc + testReport?.testItemMolecules?.length + testReport?.testItems?.length,
        0,
      );

      let calcWithCoefficient = 0;
      for (let l = 0; l < payload?.Batch?.testReports?.length; l++) {
        const singleTest = payload?.Batch?.testReports?.[l];
        for (let u = 0; u < singleTest?.testItemMolecules?.length; u++) {
          const testItemMolecule = singleTest?.testItemMolecules?.[u];
          if (testItemMolecule?.TestItem?.testCategory?.coefficient) {
            calcWithCoefficient += testItemMolecule?.TestItem?.testCategory?.coefficient;
          }
        }
        for (let u = 0; u < singleTest?.testItems?.length; u++) {
          const testItem = singleTest?.testItems?.[u];
          if (testItem?.testCategory?.coefficient) {
            calcWithCoefficient += testItem?.testCategory?.coefficient;
          }
        }
      }

      state.testScore = Math.round(((totalTestItem2 - calcWithCoefficient) / totalTestItem2) * 100);

      if (state.testScore <= 30) {
        state.noteGroupIcon = RedDotGroup;
        state.noteIcon = RedDot;
      } else if (state.testScore >= 30 && state.testScore <= 70) {
        state.noteGroupIcon = OrangeDotGroup;
        state.noteIcon = OrangeDot;
      } else if (state.testScore > 70) {
        state.noteGroupIcon = GreenDotGroup;
        state.noteIcon = GreenDot;
      }
    } else {
      state.productData = null;
      state.testAnalysisReports = null;
      state.testReportsSortedByLast = null;
      state.totalTestItem = null;
      state.testItemDetected = null;
      state.testScore = null;
      state.noteGroupIcon = GreenDotGroup;
      state.noteIcon = GreenDot;
    }
  }),

  findProductByLot: thunk(async (actions, { productId, lot }) => {
    try {
      lot = lot?.replace(/[^0-9]/g, '');
      productId = parseInt(productId);
      if (validateLotLength(lot)) {
        const res = await axios.get(`${API_URL}/Batch/lot/${productId}/${lot}`);
        actions.setProductData(res.data);
        return res.data;
      } else {
        actions.setProductData(null);
        return null;
      }
    } catch (e) {
      actions.setProductData(null);
      console.log(e);
      return null;
    }
  }),

  setProductLand: action((state, payload) => {
    state.productLand = payload;
  }),

  findProductByIndex: thunk(async (actions, payload) => {
    try {
      payload = payload?.replace(/[^0-9]/g, '');
      payload = parseInt(payload);
      const res = await axios.get(`${API_URL}/Product/viewProduct/${payload}`);
      actions.setProductLand(res.data);
      return res.data;
    } catch (e) {
      actions.setProductLand(null);
      console.log(e);
      return null;
    }
  }),
};
