import React, { useState } from 'react';
import { useStoreActions, useStoreState } from '../../store/hooks';
import Rating from 'react-rating';

//stylesheets
import './RatingModal.scss';

//images
import StarEmpty from '../../images/star_empty.svg';
import StarFull from '../../images/star_full.svg';

//components
import BrandModal from '../BrandModal/BrandModal';

const RatingModal = () => {
  const ratingModalShow = useStoreState((state) => state.ratingModal.show);
  const ratingModalToggle = useStoreActions((actions) => actions.ratingModal.toggle);
  const ratingFormModalToggle = useStoreActions((actions) => actions.ratingFormModal.toggle);
  const setRating = useStoreActions((actions) => actions.ratingFormModal.setRating);
  const givenRating = useStoreState((state) => state.ratingFormModal.rating);
  const [ratingBlur, setRatingBlur] = useState(false);
  return (
    <>
      <BrandModal isOpen={ratingModalShow} toggle={ratingModalToggle}>
        <h2 className="headline-2 mb-0">Et vous ?</h2>
        <p className="body-text-large">Quelle note donneriez-vous à ce produit ?</p>
        <div className="text-center my-4  py-2">
          <Rating
            emptySymbol={<img src={StarEmpty} className="rating-icon" alt="" />}
            placeholderSymbol={<img src={StarEmpty} className="rating-icon" alt="" />}
            fullSymbol={<img src={StarFull} className="rating-icon" alt="" />}
            fractions={2}
            initialRating={givenRating}
            onClick={(value) => {
              setRating(value);
              setRatingBlur(true);
            }}
          />
        </div>
        <div className="text-center pt-1">
          <button
            className={`min-width-120 reset-btn  body-text-large mb-3 ${
              ratingBlur ? ' brand-button ' : ' gray-button '
            }`}
            type="button"
            onClick={() => {
              if (ratingBlur) {
                ratingModalToggle();
                ratingFormModalToggle();
              }
            }}
          >
            Continuer
          </button>
        </div>
      </BrandModal>
    </>
  );
};

export default RatingModal;
