import React from 'react';
import { useStoreActions, useStoreState } from '../../store/hooks';
import config from '../../config.js';

//stylesheets
import './FindLotModal.scss';

//images
//import FindLotNumber from '../../images/find-lot-number.png';

//components
import BrandModal from '../BrandModal/BrandModal';
const { API_URL } = config;

const FindLotModal = () => {
  const findLotModalShow = useStoreState((state) => state.findLotModal.show);
  const findLotModalToggle = useStoreActions((actions) => actions.findLotModal.toggle);
  const productLand = useStoreState((state) => state.product.productLand);

  return (
    <BrandModal isOpen={findLotModalShow} toggle={findLotModalToggle} bodyClass="text-center">
      <img src={`${API_URL}/${productLand?.lotImage}`} alt="FindLotNumber" className="mb-4 d-block w-100" />
    </BrandModal>
  );
};

export default FindLotModal;
