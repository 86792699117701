import OrangeDot from '../images/orange-dot.svg';
import GreenDot from '../images/green-dot.svg';
import RedDot from '../images/red-dot.svg';

const getColorDot = (nm) => {
  switch (Number(nm)) {
    case 0:
      return GreenDot;
    case 1:
      return OrangeDot;
    default:
      return RedDot;
  }
};
export default getColorDot;
