import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useStoreState } from '../../store/hooks';
import moment from 'moment';
import 'moment-locale-fr';
import ReactPlayer from 'react-player';
import config from '../../config.js';
import { MixPanelProductFabrication } from '../../utils/mixpanel';

// stylesheets
import './Fabrication.scss';

// images
import VideoThumbnail from '../../images/video-thumbnail.png';

// components
import LoadBatch from '../../components/LoadBatch/LoadBatch';
const { API_URL } = config;

const Fabrication = () => {
  moment.locale('fr');
  const productData = useStoreState((state) => state.product.productData);
  const lotNumber = productData?.Batch?.lotNumber
  const productIndex = productData?.Batch?.product?.productIndex

  useEffect(() => {
    MixPanelProductFabrication(lotNumber, productIndex)
  },[lotNumber, productIndex])

  return (
    <>
      <LoadBatch />
      <Container fluid>
        <h2 className="headline-2 color-brand-deep text-center mb-5 mt-3 gras">Fabrication</h2>
        <p className="body-text-large mb-4">
          <span className="font-weight-bold">À {productData?.Batch?.partner?.Localisation}</span> <br />
          {moment(productData?.Batch?.creationDate, 'DD/MM/YYYY').format('[Le] dddd D MMMM YYYY')}
        </p>
      </Container>
      <Container fluid>
        <Row className="align-items-center">
          <Col xs={6}>
            <Row>
              <img src={`${API_URL}/${productData?.Batch?.user?.photo}`} className="d-block w-100" alt="" id="userPhoto"/>
            </Row>
          </Col>
          <Col xs={6}>
            <p className="body-text-medium color-brand-deep">
              {productData?.Batch?.user?.firstName}
              {` `}
              {productData?.Batch?.user?.lastName} <br />
              Employé(e) de l'usine de production
            </p>
          </Col>
        </Row>
      </Container>
      <Container className="mt-5">
          <div className="brand-quote body-text-large text-white">
              “{productData?.Batch?.user?.quote}“
          </div>
      </Container>
        {
            !productData?.Batch?.partner?.videoFabrication ? '' :
                <Container fluid>
                    <h3 className="headline-3 mt-5 mb-4">
                        Découvrez notre processus
                        <br /> de fabrication !
                    </h3>
                    <div className="video-player mb-5">
                        <ReactPlayer
                            light
                            controls
                            url={`${API_URL}/${productData?.Batch?.partner?.videoFabrication}`}
                            height="auto"
                            playing
                            width="100%"
                            playIcon={
                                <div className="video-overlay" style={{height: "160px"}}>
                                    <img src={`${API_URL}/${productData?.Batch?.partner?.videoFabrication}`} className="videoFabrication" alt="" />
                                </div>
                            }
                        />
                    </div>
                </Container>
        }

    </>
  );
};

export default Fabrication;
