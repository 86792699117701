import React from 'react';


// stylesheets
import './Comingsoon.scss';
import Container from "reactstrap/es/Container";


// images
import Load from '../../images/Load.png';

const Comingsoon = () => {

    return (
        <>
            <div className="container-fluid comingsoon-page">
                <Container className="mt-6 load" fluid>
                    <div className="load-image">
                        <img className="center" src={Load} alt=""/>
                    </div>
                    <h1 className="headline-1 text-light text-center mt-4">Toutes nos Excuses.</h1>
                    <p className="body-text-large text-light text-center">
                        Les laboratoires indépendants finalisent les analyses.
                    </p>
                    <p className="body-text-large text-light text-center">
                        Elles seront prochainement mises en ligne.
                    </p>
                    <p className="body-text-large text-light text-center">
                        Encore un peu de patience ... Merci 🙂
                    </p>
                </Container>
            </div>
        </>
    )
}

export default Comingsoon;