import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useStoreActions, useStoreState } from '../../store/hooks';
import config from '../../config.js';
import { MixPanelProductHome } from '../../utils/mixpanel';


// stylesheets
import './Home.scss';

// images
import ArrowTop from '../../images/arrow_top.svg';

// components
import ScoringMethodModal from '../../components/ScoringMethodModal/ScoringMethodModal';
import RatingDisplay from '../../components/RatingDisplay/RatingDisplay';
import TestCategory from '../../components/TestCategory/TestCategory';
import PackagingInfoModal from '../../components/PackagingInfoModal/PackagingInfoModal';
import Certificate from '../../components/Certificate/Certificate';
import ReportModal from '../../components/ReportModal/ReportModal';
import LoadBatch from '../../components/LoadBatch/LoadBatch';
import RatingSubmit from '../../components/RatingSubmit/RatingSubmit';
const { API_URL } = config;

const Home = () => {
  const scoringMethodModalToggle = useStoreActions((actions) => actions.scoringMethodModal.toggle);
  const packagingInfoModalToggle = useStoreActions((actions) => actions.packagingInfoModal.toggle);
  const reportModalToggle = useStoreActions((actions) => actions.reportModal.toggle);
  const productData = useStoreState((state) => state.product.productData);
  const setCurrentDoc = useStoreActions((actions) => actions.reportModal.setCurrentDoc);
  const testAnalysisReports = useStoreState((state) => state.product.testAnalysisReports);
  const totalTestItem = useStoreState((state) => state.product.totalTestItem);
  const totalTestItem2 = useStoreState((state) => state.product.totalTestItem2);
  const testItemDetected = useStoreState((state) => state.product.testItemDetected);
  const testScore = useStoreState((state) => state.product.testScore);
  const noteGroupIcon = useStoreState((state) => state.product.noteGroupIcon);
  const fakeTestScore = 100;
  
  
  const itemsOfProduct = productData?.Batch?.product?.Molecules?.length

  // let ope1 = (itemsOfProduct - testItemDetected) / itemsOfProduct
  let ope2 = (totalTestItem - testItemDetected)

  let ope3 = (itemsOfProduct - testItemDetected)

  const lotNumber = productData?.Batch?.lotNumber
  const productIndex = productData?.Batch?.product?.productIndex

  useEffect(() => {
    MixPanelProductHome(lotNumber, productIndex)
  }, [lotNumber, productIndex])


  console.log("productData", productData)
  console.log("lotNumber", lotNumber)
  console.log("itemsOfProduct", itemsOfProduct)
  console.log("item detected", testItemDetected)
  console.log("state", testScore)
  return (
    <>
      <LoadBatch />
      <ScoringMethodModal />
      <Container className="mt-3" fluid>
        <h3 className="headline-2 text-center color-brand-deep mb-1 gras">{productData?.Batch?.product?.Categorie}</h3>
        <h2 className="headline-3 text-center color-brand-deep mb-2">{productData?.Batch?.product?.nameFR}</h2>
        <p className="text-center body-text-small" id="lotnumber">N° DE LOT : {productData?.Batch?.lotNumber}</p>
      </Container>
      <div className="bottle-box">
        <div className="bottle-image">
          <img
            src={`${API_URL}/${productData?.Batch?.product?.productImage}`}
            alt=""
            onClick={packagingInfoModalToggle}
          />
        </div>
        <div className="bottom-box">
          <div className="result-box text-center">
            <h3 className="headline-3 mb-4">Résultat des tests de ce flacon</h3>

            <div className="mb-2">
              <img src={noteGroupIcon} alt="" />
            </div>

            <h2 className="headline-2 mb-1">
              <span className="body-text-small mr-2">NOTE</span>
              {(() => {
                switch (isNaN(testScore)) {
                  case true : return fakeTestScore;
                  case false: return testScore;
                  default: return;
                }
              })()}
              /100
              {/* {testScore}/100 */}
            </h2>
            <p className="body-text-small mb-2">
            {(() => {
                switch (itemsOfProduct === 0) {
                  case true : return 0 + "/" + 0 + " ";
                  case false: return ope3 + "/" + itemsOfProduct + " ";
                  default: return;
                }
              })()}
               résidus non détectés
            </p>
            <p className="body-text-small color-brand-lighter mb-3">
              Moyenne du marché : <b>{productData?.Batch?.product?.averageMarket}/100</b>
            </p>
            <button className="reset-btn link-text-small" id="scoringDiscoverText" onClick={scoringMethodModalToggle}>
              Découvrir notre méthode de notation
            </button>
          </div>
        </div>
      </div>
      <TestCategory />
      <Container className="text-center" fluid>
        <button
          className="reset-btn link-text-medium"
          onClick={() => {
            reportModalToggle();
            setCurrentDoc(testAnalysisReports);
          }}
        >
          Voir le rapport d’analyse détaillé
        </button>
      </Container>
      <RatingDisplay />
      <Container fluid className="mt-60 mb-60">
        <Row className="align-items-end">
          <Col xs={6}>
            <Row>
              <div className="bottle-image">
                <img
                  src={`${API_URL}/${productData?.Batch?.product?.productImage}`}
                  alt=""
                  onClick={packagingInfoModalToggle}
                />
              </div>
            </Row>
          </Col>
          <Col xs={6}>
            <Certificate />
          </Col>
        </Row>
        <p className="body-text-small mt-2 find-note">
          <img src={ArrowTop} alt="" />
          Cliquez sur le flacon pour en
          <br />
          découvrir plus sur nos emballages
        </p>
      </Container>
      <RatingSubmit />
      <ReportModal />
      <PackagingInfoModal />
    </>
  );
};

export default Home;
