import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

//components
import Layout from '../Layout/Layout';

const RouteWrapper = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props} {...rest}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

RouteWrapper.propTypes = {
  component: PropTypes.func.isRequired,
};

export default RouteWrapper;
