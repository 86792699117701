import React from 'react';
import { Container } from 'reactstrap';
import { useStoreActions, useStoreState } from '../../store/hooks';

//stylesheets
import './RatingSubmit.scss';

//images

//components
import RatingModal from '../RatingModal/RatingModal';
import RatingFormModal from '../RatingFormModal/RatingFormModal';

const RatingSubmit = () => {
  const ratingModalToggle = useStoreActions((actions) => actions.ratingModal.toggle);
  const ratingDate = useStoreState((state) => state.ratingFormModal.ratingDate);
  return (
    <>
      {!ratingDate && (
        <div className="bg-brand-lighter text-center pt-30 pb-40">
          <Container fluid>
            <h2 className="headline-2 text-white pb-3">
              Et vous, qu’en
              <br />
              pensez-vous ?
            </h2>
            <button
              type="button"
              className="min-width-210 reset-btn brand-button-white body-text-large"
              onClick={ratingModalToggle}
            >
              Je note ce produit
            </button>
          </Container>
        </div>
      )}
      <RatingModal />
      <RatingFormModal />
    </>
  );
};

export default RatingSubmit;
