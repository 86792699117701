import React from 'react';
import { Modal } from '../../components/ReactstrapModal';
import { ModalBody, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';

//stylesheets
import './BrandModal.scss';

//images
import CloseIcon from '../../images/close.svg';
import BackIcon from '../../images/back.svg';

const BrandModal = ({ children, isOpen, toggle, bodyClass, backButton, onButtonClose }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="brand-modal" container={'.app-root'}>
      <ModalHeader
        toggle={toggle}
        close={
          <button
            className="reset-btn close-btn"
            onClick={() => {
              toggle();
              if (onButtonClose) {
                onButtonClose();
              }
            }}
          >
            <img src={CloseIcon} alt="close" />
          </button>
        }
      >
        {backButton && (
          <button className="reset-btn back" onClick={toggle}>
            <img src={BackIcon} alt="BackIcon" />
          </button>
        )}
      </ModalHeader>
      <ModalBody className={bodyClass}>{children}</ModalBody>
    </Modal>
  );
};

BrandModal.propTypes = {
  children: PropTypes.any.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  bodyClass: PropTypes.string,
  backButton: PropTypes.bool,
  onButtonClose: PropTypes.func,
};

export default BrandModal;
