import { action, thunk } from 'easy-peasy';
import { addSpace } from '../utils/formatLotNumber';
import axios from 'axios';
import config from '../config.js';
const { API_URL } = config;

export default {
  search: '',
  searchSet: action((state, payload) => {
    state.search = addSpace(payload);
  }),
  autoComplete: null,
  autoCompleteSet: action((state, payload) => {
    state.autoComplete = payload;
  }),
  autoCompleteGet: thunk(async (actions, { productId, search }) => {
    try {
      search = search?.replace(/[^0-9]/g, '');
      productId = parseInt(productId);
      if (search && search?.length > 0) {
        const res = await axios.get(`${API_URL}/Batch/lot/search/${productId}/${search}`);
        actions.autoCompleteSet(res.data);
        return res.data;
      } else {
        actions.autoCompleteSet(null);
        return null;
      }
    } catch (e) {
      actions.autoCompleteSet(null);
      console.log(e);
      return null;
    }
  }),
};
