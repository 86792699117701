import { action, thunk } from 'easy-peasy';
import axios from 'axios';
import config from '../config.js';
import moment from 'moment';
const { API_URL } = config;

export default {
  show: false,
  rating: 0,
  ratingDate: '',
  toggle: action((state) => {
    state.show = !state.show;
  }),
  setRating: action((state, payload) => {
    state.rating = payload;
  }),
  updateRatingDate: action((state, payload) => {
    state.ratingDate = localStorage.getItem(payload);
  }),
  addRatingDate: action((state, payload) => {
    state.ratingDate = moment().format('DD/MM/YYYY').toString();
    localStorage.setItem(payload, state.ratingDate);
  }),
  submitRating: thunk(async (actions, payload) => {
    try {
      const res = await axios.post(`${API_URL}/rating/`, payload);
      actions.addRatingDate(payload?.product);
      return res;
    } catch (err) {
      console.log(err);
      return err;
    }
  }),
};
