import { action, thunk } from 'easy-peasy';
import axios from 'axios';
import config from '../config.js';
const { API_URL } = config;

export default {
  siteInfo: '',
  setSiteInfo: action((state, payload) => {
    state.siteInfo = payload;
  }),
  getSiteInfo: thunk(async (actions, payload) => {
    try {
      const response = await axios.get(`${API_URL}/site-info/`);
      actions.setSiteInfo(response.data);
    } catch (e) {
      console.log(e);
      actions.setSiteInfo(null);
    }
  }),
};
